import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName !== null && userName !== ''
    ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}`
    : '-'
}

const moneyToCurrencyBr = (value) => {
  if (value !== null && value !== '') {
    const money = parseFloat(value)
    return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }
  return value
}

const numberToCurrencyBr = (value) => {
  if (value !== null && value !== '') {
    const money = parseFloat(value)
    return money.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  return value
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 1:
      return '<span class="badge badge-success">Ativo</span>'
    case 0:
      return '<span class="badge badge-danger">Inativo</span>'
    default:
      return '<span class="badge badge-secondary">Indefinido</span>'
  }
}

const nameAnalysis = (value) => {
  const analysisId = parseInt(value, 10)
  switch (analysisId) {
    case 1: // Absorção Atomica
      return 'SoilAnalysisAtomicAbsorption'
    case 2: // Espectrofotometria
      return 'SoilAnalysisSpectrophotometry'
    case 3: // Titulometria
      return 'SoilAnalysisTitration'
    default:
      return ''
  }
}

export {
  createdUpdatedUser,
  moneyToCurrencyBr,
  numberToCurrencyBr,
  statusTranslate,
  nameAnalysis,
}
