window.$.extend(window.$.fn.dataTable.defaults, {
  autoWidth: false,
  dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
  language: {
    emptyTable: 'Nenhum registro encontrado',
    info: 'Mostrando de _START_ até _END_ de _TOTAL_ registros',
    infoEmpty: 'Mostrando 0 até 0 de 0 registros',
    infoFiltered: '(Filtrados de _MAX_ registros)',
    infoThousands: '.',
    loadingRecords: 'Carregando...',
    processing: 'Processando...',
    zeroRecords: 'Nenhum registro encontrado',
    search: '<span>Pesquisar:</span> _INPUT_',
    searchPlaceholder: 'Digite para filtrar...',
    paginate: {
      first: 'Primeiro',
      last: 'Último',
      next: window.$('html').attr('dir') === 'rtl' ? '&larr;' : '&rarr;',
      previous: window.$('html').attr('dir') === 'rtl' ? '&rarr;' : '&larr;',
    },
    lengthMenu: '<span>Mostrar:</span> _MENU_',
  },
})

window.$.extend(window.$.fn.dataTableExt.oStdClasses, {
  sLengthSelect: 'custom-select',
})
