<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center h-100 text-center"
  >
    <p clas="flex-1"><i class="icon-spinner2 spinner" /></p>
    <p>Carregando dados ...</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingData',
}
</script>
